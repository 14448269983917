import { ContainerLg } from '/components/Container'

import styles from './NotFound.css'

export function NotFound() {
  return (
    <div className={styles.component}>
      <ContainerLg>
        <div className={styles.inner}>
          <h1 className={styles.title}><span role='img' aria-label='shit'>💩</span> Kak</h1>
          <p>O jee, je bent in een virtueel toilet terechtgekomen - en niet de goede! Je had vast iets belangrijks nodig, maar deze pagina lijkt down the drain verdwenen te zijn. We snappen dat het frustrerend is om te verdwalen in het doolhof van het internet, maar geen zorgen: we hebben genoeg wc-humor om je te vermaken terwijl je hier bent.</p>
          <p>Wist je dat de gemiddelde persoon twee weken van zijn of haar leven doorbrengt op het toilet? Dat is genoeg tijd om de complete Harry Potter-serie twee keer te lezen! Helaas hebben we niet zoveel content op deze 404-pagina, maar misschien kun je even zitten en tot rust komen terwijl je hier bent.</p>
          <p>Als je onze website als een toilet ziet, dan zou deze 404 pagina de wc-bril zijn - het minst populaire onderdeel. Maar we beloven dat we er alles aan doen om het hier zo comfortabel mogelijk te maken. Ga gerust even plassen, was je handen en probeer het later nog eens. Misschien vind je dan wel de informatie waar je naar op zoek was. Tot die tijd wensen we je veel plezier in onze virtuele toiletruimte!</p>
        </div>
      </ContainerLg>
    </div>
  )
}
