import { initializeApp, getApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, signOut, GoogleAuthProvider } from 'firebase/auth'
import { reportError } from '/machinery/reportError'

const FirebaseContext = React.createContext(null)
const FirebaseAuthContext = React.createContext(null)

/** @returns { typeof firebase } */
export function useFirebaseApp() {
  return React.useContext(FirebaseContext)
}

export function FirebaseProvider({ name, config, children }) {
  const [firebaseApp, setFirebaseApp] = React.useState(null)

  React.useEffect(
    () => {
      let firebaseApp

      try {
        firebaseApp = getApp(name)
      } catch (_) {
        firebaseApp = initializeApp(config, name)
      }

      setFirebaseApp(firebaseApp)
    },
    [name, config]
  )

  return <FirebaseContext.Provider value={firebaseApp}>{children}</FirebaseContext.Provider>
}

export function getGoogleAuthProvider() {
  const provider = new GoogleAuthProvider()
  provider.addScope('https://www.googleapis.com/auth/userinfo.email')
  provider.setCustomParameters({ hd: 'kaliber.net' })

  return provider
}

export function useFirebaseAuth() {
  return React.useContext(FirebaseAuthContext)
}

export function FirebaseAuthProvider({ children }) {
  const [error, setError] = React.useState(null)
  const [isFetchingAuthentication, setIsFetchingAuthentication] = React.useState(true)
  const [{ isLoggedIn, userData }, setUser] = React.useState({ userData: {}, isLoggedIn: false })
  const firebaseApp = useFirebaseApp()

  React.useEffect(
    () => {
      if (!firebaseApp) return

      const auth = getAuth(firebaseApp)
      const unsubscribe = onAuthStateChanged(auth, user => {
        if (user) {
          // super basic validation
          if (user.email.endsWith('@kaliber.net')) {
            setUser({ userData: user, isLoggedIn: true })
          } else {
            signOut(auth)
              .then(() => setError('Alleen @kaliber.net e-mailadressen hebben toegang'))
              .catch(e => {
                reportError(e)
                setError(e.message)
              })
          }
        } else {
          setUser({ userData: {}, isLoggedIn: false })
        }

        setIsFetchingAuthentication(false)
      })

      return () => unsubscribe()
    },
    [firebaseApp]
  )

  return <FirebaseAuthContext.Provider value={{ error, isFetchingAuthentication, isLoggedIn, userData }}>{children}</FirebaseAuthContext.Provider>
}

export function FirebaseAuthConsumer({ children }) {
  return <FirebaseAuthContext.Consumer>{children}</FirebaseAuthContext.Consumer>
}
