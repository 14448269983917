import { useFirebaseApp, useFirebaseAuth, getGoogleAuthProvider } from '/machinery/firebase'
import { getAuth, signInWithPopup } from 'firebase/auth'
import { useNavigate } from '@kaliber/routing'
import { reportError } from '/machinery/reportError'
import { ContainerLg } from '/components/Container'

import styles from './Login.css'

export function Login() {
  const provider = getGoogleAuthProvider()
  const firebaseApp = useFirebaseApp()
  const [errorMessage, setErrorMessage] = React.useState(null)
  const { isLoggedIn } = useFirebaseAuth()
  const navigate = useNavigate()

  React.useEffect(() => { if (isLoggedIn) navigate('/') }, [isLoggedIn])

  if (isLoggedIn) return null

  return (
    <div className={styles.component}>
      <ContainerLg layoutClassName={styles.sectionLayout}>
        <div className={styles.inner}>
          <h1 className={styles.title}>Is het bezet of niet?</h1>
          <p className={styles.description}>Om te kunnen zien of de toiletten bezet zijn, dien je ingelogd te zijn. Gelukkig is inloggen super eenvoudig! Gebruik de onderstaande knop om in te loggen met je @kaliber.net Google account en je kunt direct zien of de toiletten vrij zijn of niet. Geen tijd te verliezen!</p>

          {errorMessage && <div className={styles.error}>{errorMessage}</div>}

          <div className={styles.buttonContainer}>
            <button className={styles.button} onClick={handleOnClick}>
              Inloggen maar! <span role='img' aria-label='raket'>🚀</span>
            </button>
          </div>
        </div>
      </ContainerLg>
    </div>
  )

  function handleOnClick(evt) {
    evt.preventDefault()
    signInWithPopup(getAuth(firebaseApp), provider).catch(e => {
      reportError(e)
      setErrorMessage(e.message)
    })
  }
}
