import { PageLayout } from '/components/PageLayout'
import { ClientConfigProvider } from '/machinery/ClientConfig'
import { FirebaseProvider, FirebaseAuthProvider, FirebaseAuthConsumer } from '/machinery/firebase'
import { Home } from '/pages/Home'
import { Login } from '/pages/Login'
import { NotFound } from '/pages/NotFound'
import { LocationProvider, asRouteMap, useNavigate, useRouting } from '@kaliber/routing'

const routeMap = asRouteMap({
  root: '',
  login: 'login',
  notFound: '*',
})

export default function App({ config, initialLocation }) {
  return (
    <FirebaseProvider config={config.firebase} name='kaliber-toilet-status'>
      <ClientConfigProvider {...{ config }}>
        <FirebaseAuthProvider>
          <LocationProvider {...{ initialLocation, routeMap }}>
            <FirebaseAuthConsumer>
              {({ isFetchingAuthentication, isLoggedIn, error }) => <AppWithAuth {...{ isFetchingAuthentication, isLoggedIn, error }} />}
            </FirebaseAuthConsumer>
          </LocationProvider>
        </FirebaseAuthProvider>
      </ClientConfigProvider>
    </FirebaseProvider>
  )
}

function AppWithAuth({ isFetchingAuthentication, isLoggedIn, error: globalErrorMessage }) {
  const { matchRoutes } = useRouting()

  if (isFetchingAuthentication) return null
  return (
    <PageLayout {...{ isLoggedIn, globalErrorMessage }}>
      {matchRoutes(
        [routeMap.root, <ProtectedRoute component={Home} />],
        [routeMap.notFound, <ProtectedRoute component={NotFound} />],
        [routeMap.login, <PublicRoute component={Login} {...{ globalErrorMessage }} />],
      )}
    </PageLayout>
  )
}

function ProtectedRoute({ component: Component, ...restProps }) {
  return (
    <FirebaseAuthConsumer>
      {({ isLoggedIn }) =>
        isLoggedIn ? <Component {...restProps} /> : <Redirect to="/login" />
      }
    </FirebaseAuthConsumer>
  )
}

function PublicRoute({ component: Component, ...restProps }) {
  return <Component {...restProps} />
}

function Redirect({ to }) {
  const navigate = useNavigate()

  React.useEffect( () => { navigate(to) }, [to] )

  return null
}
