import { Footer } from '/components/Footer'

import styles from './PageLayout.css'

export function PageLayout({ children, isLoggedIn, globalErrorMessage = undefined }) {
  return (
    <div className={styles.component}>
      <div className={styles.main}>
        {children}
      </div>
      <div className={styles.footer}>
        <Footer {...{ isLoggedIn }} />
      </div>
      {globalErrorMessage && <div className={styles.globalError}>{globalErrorMessage}</div>}
    </div>
  )
}
