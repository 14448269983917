import { ContainerMd } from '/components/Container'
import { useFirebaseApp } from '/machinery/firebase'
import { getAuth, signOut } from 'firebase/auth'
import { reportError } from '/machinery/reportError'

import styles from './Footer.css'

export function Footer({ isLoggedIn }) {
  const firebaseApp = useFirebaseApp()

  return (
    <div className={styles.component}>
      <ContainerMd>
        <div className={styles.inner}>
          Gemaakt met een beetje <span role='img' aria-label='magie'>🪄</span> 
          en <span role='img' aria-label='liefde'>❤️</span>
          {isLoggedIn && (
            <React.Fragment>
              <span className={styles.seperator}>|</span>
              <a className={styles.link} href="#logout" onClick={handleOnClick}>Uitloggen</a>
            </React.Fragment>
          )}
        </div>
      </ContainerMd>
    </div>
  )

  function handleOnClick(evt) {
    evt.preventDefault()
    const auth = getAuth(firebaseApp)
    signOut(auth).catch(e => { reportError(e) })
  }
}
